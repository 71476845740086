import React, { useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import QRCode from 'react-qr-code';
import { makeStyles } from '@material-ui/core/styles'
import { format, set } from 'date-fns'

import { gql, useMutation, useQuery } from '@apollo/client'

import Grid from '@material-ui/core/Grid'
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  Button
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import Switch from '@material-ui/core/Switch'

import Spinner from '../../components/spinner'
import Title from '../../components/Title'
import {
  AddChannelDocument,
  AddPaymentMethodDocument,
  CopyMachineConfigDocument,
  CopyMachineConfigInput,
  DeleteChannelDocument,
  MachineById_MachineInfoDocument,
  MachinesDocument,
  PaymentMethod,
  RemovePaymentMethodDocument,
  SetFridgeDocument,
  SetActiveDocument,
  SetLocationDocument,
  MachineLocationsDocument,
  SetMachineLocationDocument,
  ConfigKey,
} from '../../generated'
import { ChannelConfiguration } from './ChannelConfiguration'
import { CopyMachineDialog } from './CopyMachineDialog'
import SingleMachineQRCodeScreen from './SingleMachineQRCode'

import { CardTerminals } from './CardTerminals'
import { ProductCapacity } from './ProductCapacity';
import { Checkbox, TextField } from '@material-ui/core'
import ReactDOM from 'react-dom';

const useStyles = makeStyles((theme) => ({
  backArrow: {
    color: theme.palette.primary.main,
    '&:hover': { textDecoration: 'underline' },
  },
  infoRow: { marginTop: '0.5rem', marginBottom: '0.5rem' },
  label: { fontWeight: 600 },
  formControl: { minWidth: 250 },
}))

// function debounce(func:any, wait:number) {
//   let timeout:any;
//   return function executedFunction(...args:any) {
//     const later = () => {
//       clearTimeout(timeout);
//       func(...args);
//     };
//     clearTimeout(timeout);
//     timeout = setTimeout(later, wait);
//   };
// }

const daysOfWeek = [
  "Not automatic",
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

const SET_MACHINE_LOCKING = gql`
  mutation SetMachineLocking($input: SaveMachineLockingConfigInput!) {
    saveMachineLockingConfig(input: $input) {
      id
    }
  }
`;

export const SingleMachineScreen = () => {
  const classes = useStyles()
  const params = useParams()
  const machineId = params.id || ''
  const [copyMachineModalOpen, setCopyMachineModalOpen] = useState(false)
  const { loading, error, data } = useQuery(MachineById_MachineInfoDocument, {
    variables: { id: machineId },
  })
  const { 
    loading: loadingMachineLocations,
    error: errorMachineLocations,
    data: dataMachineLocations
  } = useQuery(MachineLocationsDocument)
  const navigate = useNavigate()
  const [addChannel] = useMutation(AddChannelDocument)
  const [deleteChannel] = useMutation(DeleteChannelDocument)
  const [addPaymentMethod] = useMutation(AddPaymentMethodDocument)
  const [removePaymentMethod] = useMutation(RemovePaymentMethodDocument)
  const [copyMachineConfig] = useMutation(CopyMachineConfigDocument)
  const [setFridge] = useMutation(SetFridgeDocument)
  const [setActive] = useMutation(SetActiveDocument)
  const [saveLocation] = useMutation(SetLocationDocument)
  const [saveMachineLocation] = useMutation(SetMachineLocationDocument)
  const [location, setLocation] = useState(data?.machineById.location || '');
  const [selectedLockDay, setSelectedLockDay] = React.useState(-1);
  const [selectedUnlockDay, setSelectedUnlockDay] = React.useState(-1);
  const [selectedLockHour, setSelectedLockHour] = useState(0);
  const [selectedUnlockHour, setSelectedUnlockHour] = useState(0);
  const [setMachineLockingConfig] = useMutation(SET_MACHINE_LOCKING);
  const [saveLockText, setSaveLockText] = useState('Save configuration');
  React.useEffect(() => {
    const selectedDay = data?.machineById.machineConfigs.find((c) => c.key === ConfigKey.LockDay)
    setSelectedLockDay(selectedDay ? parseInt(selectedDay.value, 10) : 6)
    const selectedHour = data?.machineById.machineConfigs.find((c) => c.key === ConfigKey.LockHour)
    setSelectedLockHour(selectedHour ? parseInt(selectedHour.value, 10) : 0)
    const selectedUnlockDay = data?.machineById.machineConfigs.find((c) => c.key === ConfigKey.UnlockDay)
    setSelectedUnlockDay(selectedUnlockDay ? parseInt(selectedUnlockDay.value, 10) : 1)
    const selectedUnlockHour = data?.machineById.machineConfigs.find((c) => c.key === ConfigKey.UnlockHour)
    setSelectedUnlockHour(selectedUnlockHour ? parseInt(selectedUnlockHour.value, 10) : 0)
  }, [data?.machineById.machineConfigs])

  if (loading || !data || loadingMachineLocations || !dataMachineLocations) return <Spinner />
  if (error || !machineId || errorMachineLocations) return <p>Error {JSON.stringify(error, null, 2)}</p>

  const handleLockDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLockDay(parseInt(event.target.value, 10));
    setSaveLockText('Save configuration');
  };
  const handleLockHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLockHour(parseInt(event.target.value, 10));
    setSaveLockText('Save configuration');
  };
  const handleUnlockDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUnlockDay(parseInt(event.target.value, 10));
    setSaveLockText('Save configuration');
  };
  const handleUnlockHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUnlockHour(parseInt(event.target.value, 10));
    setSaveLockText('Save configuration');
  };

  const handleSaveLockingConfiguration = async () => {
    setSaveLockText('Saving...');
    try {
      const { data } = await setMachineLockingConfig({
        variables: {
          input: {
            machineId,
            lockDay: selectedLockDay,
            lockHour: selectedLockHour,
            unlockDay: selectedUnlockDay,
            unlockHour: selectedUnlockHour,
          }
        },
      });
      setSaveLockText('Configuration saved');
    } catch (error) {
      setSaveLockText('Error saving configuration');
      console.error('Error setting machine config:', error);
    }
  };

  const handleTogglePaymentMethod =
    (method: PaymentMethod) =>
    (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const args = {
        variables: { input: { machineId, method } },
        refetchQueries: [
          {
            query: MachineById_MachineInfoDocument,
            variables: { id: machineId },
          },
        ],
      }
      if (checked) addPaymentMethod(args)
      else removePaymentMethod(args)
    }

  const handleAddChannel = (channel: number) => {
    return addChannel({
      variables: { input: { machineId, channel } },
      refetchQueries: [
        {
          query: MachineById_MachineInfoDocument,
          variables: { id: machineId },
        },
      ],
    })
  }

  const handleRemoveChannel = (channel: number) => {
    return deleteChannel({
      variables: { input: { machineId, channel } },
      refetchQueries: [
        {
          query: MachineById_MachineInfoDocument,
          variables: { id: machineId },
        },
      ],
    })
  }
  const handleSetFridge = (isFridge: number) => {
    return setFridge({
      variables: { input: { machineId, isfridge: isFridge } },
      refetchQueries: [
        {
          query: MachineById_MachineInfoDocument,
          variables: { id: machineId },
        },
      ],
    })
  }

  const handleSetActive = (active: boolean) => {
    return setActive({
      variables: { input: { machineId, active } },
      refetchQueries: [
        {
          query: MachineById_MachineInfoDocument,
          variables: { id: machineId },
        },
      ],
    })
  }

  const handleSetLocation = (location: string) => {
    return saveLocation({
      variables: { input: { machineId, location } },
      refetchQueries: [
        {
          query: MachineById_MachineInfoDocument,
          variables: { id: machineId },
        },
      ],
    })
  }

  const handleSetMachineLocationId = (machineLocationId: string) => {
    return saveMachineLocation({
      variables: { input: { machineId, machineLocationId } },
      refetchQueries: [
        {
          query: MachineById_MachineInfoDocument,
          variables: { id: machineId },
        },
      ],
    })
  }

  const handleCopyMachineConfig = async (
    config: Omit<CopyMachineConfigInput, 'machineId'>
  ) => {
    const res = await copyMachineConfig({
      variables: { input: { ...config, machineId } },
      refetchQueries: [{ query: MachinesDocument }],
    })
    if (res.data?.copyMachineConfig)
      navigate(`/machines/${res.data.copyMachineConfig.id}`)
  }

  const machine = data.machineById

  const machineInfo = [
    { key: 'ID', label: 'ID', value: machine.id },
    { key: 'location', label: 'Machine name', value: machine.location },
    { key: 'active', label: 'Active', value: machine.active ? 'TRUE' : 'FALSE' },
    {
      key: 'launchDate',
      label: 'Launch',
      value: machine.launchDate
        ? format(new Date(machine.launchDate), 'dd-MM-yyyy')
        : '?',
    },
  ]

  const handleQRCodeClick = () => {
    const qrCodeValue = `${machine.id}`;
    const newWindow = window.open('', '_blank', 'width=600,height=600');
    if (newWindow) {
      newWindow.document.write('<div id="qr-code-container"></div>');
      newWindow.document.close();
      newWindow.onload = () => {
        const container = newWindow.document.getElementById('qr-code-container');
        if (container) {
          ReactDOM.render(<SingleMachineQRCodeScreen machineId={qrCodeValue} />, container);
        }
      };
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Link className={classes.backArrow} to="/machines">
            &larr; Go back
          </Link>
          <Title>{machine.location}</Title>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {machineInfo.map(({ key, label, value }) => (
            <Grid key={key} container spacing={3}>
              <Grid item xs={3} sm={2} className={classes.label}>
              {!(['location','active'].includes(key)) ? (
                `${label}:`
              ) : (
                <div style={{ marginTop: key === 'location' ? '20px' : '10px' }} aria-label={label}>
                  {label}:
                </div>
              )}
              </Grid>
              <Grid item xs={9} sm={10}>
              {key === 'location' && (
                  <>
                    <TextField
                      onChange={(event) => setLocation(event.target.value)}
                      value={location} // Use the state value for the TextField
                      // InputLabelProps={{ shrink: false }} // Prevent the label from taking up space
                      aria-label="New location" // Provide an accessible name
                      margin="none" // Remove the top margin
                      label={machine.location}
                    />
                    <IconButton
                      onClick={() => handleSetLocation(location)}
                    >
                      <CheckIcon />
                    </IconButton>
                  </>
              )}
              {key === 'active' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled ={false}
                      onChange={(event) => handleSetActive(event.target.checked)}
                      checked={data.machineById.active}
                    />
                  }
                  label="Active"
                />
              )}
              {!(['location', 'active'].includes(key)) && (  
                <div aria-label={label}>
                  {value}
                </div>
              )}
              </Grid>
            </Grid>
          ))}
          <Grid key={'isfridge'} container spacing={3}>
            <Grid item xs={3} sm={2} className={classes.label} style={{ marginTop: '12px' }}>
              Is Fridge:
            </Grid>
            <Grid item xs={9} sm={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled ={false}
                    onChange={(event) => handleSetFridge(event.target.checked ? 1 : 0)}
                    checked={data.machineById.isfridge ? true : false}
                  />
                }
                label="Is Fridge"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="machine-location-label">Machine Location</InputLabel>
            <Select
              labelId="machine-location-label"
              value={data.machineById.machineLocationId || ''}
              onChange={(e) => handleSetMachineLocationId(e.target.value as string)}
              label="Machine Location"
            >
              {dataMachineLocations.machineLocations.map((machineLocation) => (
                <MenuItem key={machineLocation.id} value={machineLocation.id}>
                  {machineLocation.locationName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <QRCode
            size={50}
            style={{ cursor: 'pointer' }}
            onClick={handleQRCodeClick}
            value={`https://veat.se/webapp/?${data.machineById.id}`}
          />
        </Grid>
        <Grid item xs={12}>
          <CardTerminals
            machineId={data.machineById.id}
            pointOfServiceId={data.machineById.pointOfServiceId}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 style={{ marginBottom: '0' }}>Payment Methods</h2>
        </Grid>
        {Object.values(PaymentMethod).map((paymentMethod) => (
          <FormControlLabel
            key={paymentMethod}
            control={
              <Switch
                color="primary"
                onChange={handleTogglePaymentMethod(paymentMethod)}
                checked={machine.paymentMethods.includes(paymentMethod)}
              />
            }
            label={paymentMethod}
            labelPlacement="top"
            style={{ marginLeft: '6px' }}
          />
        ))}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ChannelConfiguration
            onAddChannel={handleAddChannel}
            onRemoveChannel={handleRemoveChannel}
            channels={machine.channels}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CopyMachineDialog
            open={copyMachineModalOpen}
            onOpen={() => setCopyMachineModalOpen(true)}
            onClose={() => setCopyMachineModalOpen(false)}
            onSubmit={handleCopyMachineConfig}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ProductCapacity machineId={machineId} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h2 style={{ marginBottom: '10' }}>Machine Configuration</h2>
        <h3 style={{ marginTop: '0' }}>Lock Day</h3>
        <RadioGroup row value={selectedLockDay} onChange={handleLockDayChange}>
          {daysOfWeek.map((day, index) => (
            <FormControlLabel
              key={index - 1}
              value={index - 1}
              control={<Radio />}
              label={day}
            />
          ))}
          <TextField
            label="Lock Hour"
            type="number"
            value={selectedLockHour}
            onChange={handleLockHourChange}
            inputProps={{ min: 0, max: 23 }}
            variant="outlined"
          />
        </RadioGroup>

        <h3 style={{ marginTop: '0' }}>Unlock Day</h3>
        <RadioGroup row value={selectedUnlockDay} onChange={handleUnlockDayChange}>
          {daysOfWeek.map((day, index) => (
            <FormControlLabel
              key={index - 1}
              value={index - 1}
              control={<Radio />}
              label={day}
            />
          ))}
          <TextField
            label="Unlock Hour"
            type="number"
            value={selectedUnlockHour}
            onChange={handleUnlockHourChange}
            inputProps={{ min: 0, max: 23 }}
            variant="outlined"
          />
        </RadioGroup>
        <Button
          variant="contained"
          color="primary" 
          onClick={handleSaveLockingConfiguration}
        >{ saveLockText }</Button>
      </Grid>
    </>
  )
}
