import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { MachineById_MachineInfoDocument, ProductsDocument, AddProductCapacityDocument, RemoveProductCapacityDocument } from '../../generated';
import { IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, InputLabel, FormControl, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export const ProductCapacity = ({ machineId }: { machineId: string }) => {
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [capacity, setCapacity] = useState('');

  const { loading: loadingMachine, error: errorMachine, data: dataMachine } = useQuery(MachineById_MachineInfoDocument, {
    variables: { id: machineId },
  });

  const { loading: loadingProducts, error: errorProducts, data: dataProducts } = useQuery(ProductsDocument, {
    variables: { activeOnly: true },
  });

  const [addProductCapacity] = useMutation(AddProductCapacityDocument, {
    refetchQueries: [{ query: MachineById_MachineInfoDocument, variables: { id: machineId } }],
  });

  const [removeProductCapacity] = useMutation(RemoveProductCapacityDocument, {
    refetchQueries: [{ query: MachineById_MachineInfoDocument, variables: { id: machineId } }],
  });

  if (loadingMachine || loadingProducts) return <p>Loading...</p>;
  if (errorMachine) return <p>Error: {errorMachine.message}</p>;
  if (errorProducts) return <p>Error: {errorProducts.message}</p>;

  const handleDelete = async (productId: string) => {
    try {
      await removeProductCapacity({
        variables: {
          input: {
            machineId,
            productId,
          },
        },
      });
    } catch (error) {
      console.error('Error removing product capacity:', error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddCapacity = async () => {
    try {
      await addProductCapacity({
        variables: {
          input: {
            machineId,
            productId: selectedProduct,
            capacity: parseInt(capacity, 10),
          },
        },
      });
      handleClose();
    } catch (error) {
      console.error('Error adding product capacity:', error);
    }
  };

  return (
    <div>
      <h2>Capacity</h2>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Capacity
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Capacity</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="product-select-label">Product</InputLabel>
            <Select
              labelId="product-select-label"
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value as string)}
            >
              {dataProducts && dataProducts.products
                .slice() // Create a shallow copy to avoid mutating the original array
                .sort((a: any, b: any) => {
                  const nameA = a.texts.find((t: { locale: string }) => t.locale === 'EN')?.name || '';
                  const nameB = b.texts.find((t: { locale: string }) => t.locale === 'EN')?.name || '';
                  return nameA.localeCompare(nameB);
                })
                .map((product: any) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.texts.find((t: { locale: string }) => t.locale === 'EN')?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Capacity"
            type="number"
            value={capacity}
            onChange={(e) => setCapacity(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddCapacity} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {dataMachine && dataMachine.machineById.productCapacities && (
        <Paper style={{ width: '80%', margin: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Capacity</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataMachine.machineById.productCapacities
                .slice() // Create a shallow copy to avoid mutating the original array
                .sort((a: any, b: any) => {
                  const nameA = a.product.texts.find((t: { locale: string }) => t.locale === 'EN')?.name || '';
                  const nameB = b.product.texts.find((t: { locale: string }) => t.locale === 'EN')?.name || '';
                  return nameA.localeCompare(nameB);
                })
                .map((capacity: any) => (
                  <TableRow key={capacity.productId}>
                    <TableCell>{capacity.product.texts.find((t: { locale: string }) => t.locale === 'EN')?.name}</TableCell>
                    <TableCell>{capacity.capacity}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDelete(capacity.productId)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </div>
  );
};