import React, { useState, useEffect, createRef } from 'react'

import { gql, useMutation } from '@apollo/client'
import { useMultilog } from '../context/multilog'
import Spinner from '../components/spinner'

import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import RemoveIcon from '@material-ui/icons/Remove'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import Dialog from '../components/dialog'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { makeStyles } from '@material-ui/core/styles'
import { getTextForLocale, findProductById } from '../helpers'
import { MachineByIdDocument, StockSnapshotSource } from '../generated'

const useStyles = makeStyles((theme) => ({
  textField: { width: '4rem' },
}))

const DELETE_STOCK_MUTATION = gql`
  mutation DeleteStock($id: ID!) {
    deleteStock(id: $id) {
      id
    }
  }
`

const CHANGE_STOCK_QUANTITY_BY_ONE_MUTATION = gql`
  mutation ChangeStockQuantityByOne($input: ChangeStockQuantityByOneInput!) {
    changeStockQuantityByOne(input: $input) {
      id
    }
  }
`

const INVENTORY_STOCK_TRANSACTION_V2 = gql`
  mutation InventoryStockTransactionV2($input: StockTransactionV2InventoryInput!) {
    inventoryStockTransactionV2(input: $input) {
      balance
    }
  }
`;

// TODO: Include product on workingstock
const Stock = ({
  stock,
  occupiedChannels,
  workingStock,
  onBlur,
  onQuantityKeyPress,
  onValueChange,
  onDecreaseClick,
  onIncreaseClick,
  onDeleteClick,
  inputRef,
  editMode,
  channels,
  menuItems,
}) => {
  const classes = useStyles()
  const stocksPerChannel = occupiedChannels.reduce(
    (acc, curr) => ({ ...acc, [curr]: (acc[curr] || 0) + 1 }),
    {}
  )

  const changesMadeStyles = {
    border: '4px solid lightgreen',
    borderRadius: '6px',
  }

  const getWorkingNumberStyle = (type) => {
    const styles = { textAlign: 'center' }
    const value = workingStock[type]
    const numberRegex = /^([0-9]|[1-9][0-9]*)$/

    if (!numberRegex.test(value) || stocksPerChannel[value] > 1) {
      return { ...styles, border: '4px solid red', borderRadius: '6px' }
    }
    if (+value !== stock[type]) {
      return { ...styles, changesMadeStyles }
    }
    return styles
  }

  const getMenuItemStyle = () => {
    if (workingStock.menuItem.id !== stock.menuItem.id) {
      return changesMadeStyles
    }
  }

  if (editMode)
    return (
      <TableRow hover>
        <TableCell>
          <FormControl variant="filled" style={{ minWidth: 80 }}>
            <Select
              variant="outlined"
              style={{ ...getWorkingNumberStyle('channel') }}
              value={workingStock.channel}
              onChange={(e) => {
                const value = e.target.value
                onBlur('channel', stock)
                onValueChange('channel', stock.id, value)
              }}
            >
              {channels.map((c) => (
                <MenuItem value={c}>{c}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <FormControl variant="filled" style={{ minWidth: 80 }}>
            <Select
              variant="outlined"
              style={{ ...getMenuItemStyle() }}
              value={workingStock.menuItem.id}
              onChange={(e) => {
                const product = findProductById(menuItems, e.target.value)
                onBlur('menuItem', stock)
                onValueChange('menuItem', stock.id, product)
              }}
            >
              {menuItems.map((menuItem) => (
                <MenuItem key={menuItem.id} value={menuItem.id}>
                  {getTextForLocale(menuItem.product).name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <TextField
            inputRef={inputRef}
            variant="outlined"
            className={classes.textField}
            inputProps={{
              style: { ...getWorkingNumberStyle('quantity') },
            }}
            value={workingStock.quantity}
            onBlur={() => onBlur('quantity', stock)}
            onChange={(e) => {
              const value = e.target.value
              onValueChange('quantity', stock.id, value)
            }}
            onKeyPress={(e) => onQuantityKeyPress(e)}
          />
        </TableCell>
        <TableCell>
          { workingStock.quantity || 0 === 0 && <IconButton onClick={() => onDeleteClick(stock)}>
            <DeleteIcon />
           </IconButton>
          }
        </TableCell>
      </TableRow>
    )

  return (
    <TableRow hover key={stock.channel} role="checkbox">
      <TableCell>{stock.channel}</TableCell>
      <TableCell>{getTextForLocale(stock.menuItem.product).name}</TableCell>
      <TableCell>
        <IconButton
          color="primary"
          aria-label="subtract"
          onClick={() => onDecreaseClick(stock)}
        >
          <RemoveIcon />
        </IconButton>
        {stock.quantity}
        <IconButton
          aria-label="add"
          color="primary"
          onClick={() => onIncreaseClick(stock)}
        >
          <AddIcon />
        </IconButton>
      </TableCell>
      <TableCell>
        { stock.quantity === 0 && <IconButton onClick={() => onDeleteClick(stock)}>
          <DeleteIcon />
        </IconButton>
        }
      </TableCell>
    </TableRow>
  )
}

const Stocks = ({
  machineId,
  loading,
  error,
  stocks,
  menuItems,
  editMode,
  workingStocks,
  setWorkingStocks,
  channels,
}) => {
  const { multilog, multilogLog } = useMultilog()
  const [deleteStockItem] = useMutation(DELETE_STOCK_MUTATION)
  const [changeStockQuantityByOne] = useMutation(
    CHANGE_STOCK_QUANTITY_BY_ONE_MUTATION
  )
  const [stockTransactionV2Inventory] = useMutation(INVENTORY_STOCK_TRANSACTION_V2)  
  const [deleteStockDialog, setDeleteStockDialog] = useState(null)
  const [inputRefs, setInputRefs] = useState([])

  useEffect(() => {
    if (stocks) setInputRefs(stocks.map(() => createRef()))
  }, [stocks])

  const handleDeleteClick = React.useCallback(async(stock) => {
    await multilogLog(
      multilog.multilog?.clientToken || '',
      5,
      'Dashboard.InventoryScreen.Stocks',
      `Clicked delete on stock  ${JSON.stringify(stock)}`,
      machineId,
      multilog.multilog?.userId || ''
    )
    setDeleteStockDialog(stock)
  }, [])

  const handleDeleteSubmit = React.useCallback(
    async(stock) => {
      await multilogLog(
        multilog.clientToken || '',
        5,
        'Dashboard.InventoryScreen.Stocks.DeleteStock',
        `input: ${JSON.stringify({
          stockId: stock.id
        })}`,
        multilog.machineId || '',
        multilog.userId || ''
      )
      await stockTransactionV2Inventory({
        variables: {
          input: {
            machineId,
            channel: stock.channel,
            productId: stock.menuItem.product.id,
            balance: 0
          }
        }
      }).then(async() => {
        await deleteStockItem({
          variables: { id: stock.id },
          refetchQueries: [
            { query: MachineByIdDocument, variables: { id: machineId } },
          ],
        }).then(() => setDeleteStockDialog(null))
      })
    },
    [machineId, deleteStockItem, stockTransactionV2Inventory]
  )

  const handleStockIncrement = React.useCallback(
    (isDecrease) => async(stock) => {
      const stockId = stock.id
      const productId = stock.menuItem.product.id
      const channel = stock.channel
      const balance = isDecrease ? stock.quantity - 1 : stock.quantity + 1
      const stockTransInput = {
        machineId,
        productId,
        channel,
        balance
      }
      await multilogLog(
        multilog.clientToken || '',
        5,
        'Dashboard.InventoryScreen.Stocks.stockTransactionV2Inventory',
        `input: ${JSON.stringify(stockTransInput)}`,
        multilog.machineId || '',
        multilog.userId || ''
      )
      await stockTransactionV2Inventory({
        variables: {
          input: stockTransInput
        }
      })
      .then(async() => {
        const changeStockQuantityByOneInput = {
          id: stockId,
          isDecrease,
          source: StockSnapshotSource.Adjustment,
        }
        await multilogLog(
          multilog.clientToken || '',
          5,
          'Dashboard.InventoryScreen.Stocks.changeStockQuantityByOne',
          `input: ${JSON.stringify(changeStockQuantityByOneInput)}`,
          multilog.machineId || '',
          multilog.userId || ''
        )
        await changeStockQuantityByOne({
          variables: {
            input: changeStockQuantityByOneInput
          },
          refetchQueries: [
            { query: MachineByIdDocument, variables: { id: machineId } },
          ],
        })
      })
    },
    [machineId, changeStockQuantityByOne, stockTransactionV2Inventory]
  )

  const handleBlur = React.useCallback(
    (type, stock) => {
      if (workingStocks[stock.id][type] === '') {
        setWorkingStocks((prevWorkingStocks) => ({
          ...prevWorkingStocks,
          [stock.id]: { ...prevWorkingStocks[stock.id], [type]: stock[type] },
        }))
      }
    },
    [workingStocks, setWorkingStocks]
  )

  const handleQuantityKeyPress = (idx) => (e) => {
    if (e.key === 'Enter') {
      if (idx !== stocks.length - 1) {
        inputRefs[idx + 1].current.focus()
        inputRefs[idx + 1].current.select()
      }
    }
  }

  const handleValueChange = React.useCallback((field, stockId, value) => {
    setWorkingStocks((prevWorkingStocks) => ({
      ...prevWorkingStocks,
      [stockId]: { ...prevWorkingStocks[stockId], [field]: value },
    }))
  }, [setWorkingStocks])

  if (loading) return <Spinner />
  if (error)
    return (
      <TableRow>
        <TableCell>Error :({JSON.stringify(error, null, 2)}</TableCell>
      </TableRow>
    )

  return (
    <>
      <Dialog
        title="Are you sure?"
        open={!!deleteStockDialog}
        description={`Are you sure you want to delete ${
          getTextForLocale(deleteStockDialog?.menuItem.product).name
        } on channel ${deleteStockDialog?.channel}?`}
        onClose={() => setDeleteStockDialog(null)}
        handleSubmit={() => handleDeleteSubmit(deleteStockDialog || null)}
        primaryActionText="Delete"
      />
      {stocks.map((stock, idx) => (
        <Stock
          key={stock.id}
          stock={stock}
          occupiedChannels={Object.values(workingStocks).map((s) => s.channel)}
          workingStock={workingStocks[stock.id]}
          menuItems={menuItems}
          onBlur={handleBlur}
          onQuantityKeyPress={handleQuantityKeyPress(idx)}
          onValueChange={handleValueChange}
          onDecreaseClick={handleStockIncrement(true)}
          onIncreaseClick={handleStockIncrement(false)}
          onDeleteClick={handleDeleteClick}
          inputRef={inputRefs[idx]}
          editMode={editMode}
          channels={channels}
        />
      ))}
    </>
  )
}

export default Stocks
