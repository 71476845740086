import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { createClient } from './createClient'
import { ApolloProvider } from '@apollo/client'
import { useAuth } from './context/auth'
import Spinner from './components/spinner'
import { MultilogProvider } from './context/multilog'

const generateGUID = () => {
  const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  return template.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const emptyGuid = '11111111-1111-4111-8111-111111111111';

export const ApolloWrapper = () => {
  const [apiKey, setApiKey] = useState<string | null>(null)
  const { user } = useAuth()
  const [clientToken, setClientToken] = useState<string | null>(null)
  useEffect(() => {
    if (!clientToken) {
      setClientToken(generateGUID())
    }
  }, []);
  
  useEffect(() => {
    if (user) {
      ;(async () => {
        const idToken = await user.getIdToken()

        try {
          const res = await fetch(
            `${process.env.REACT_APP_API_URI}/api/auth/get-api-token`,
            { headers: {
                Authorization: idToken,
                'X-Client-Token': clientToken || emptyGuid,
            } }
          )
          const data = await res.json()
          setApiKey(data.apiKey)
        } catch (_e) {}
      })()
    } else {
      setApiKey(null)
    }
  }, [user])

  return apiKey ? (
    <ApolloProvider client={createClient(apiKey, clientToken || emptyGuid)}>
      <MultilogProvider clientToken={clientToken || emptyGuid}>
        <Outlet />
      </MultilogProvider>
    </ApolloProvider>
  ) : (
    <Spinner />
  )
}
