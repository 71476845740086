import React, { useState, createContext, useContext, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useAuth } from './auth';
import { gql } from '@apollo/client';

const  MULTILOG_INIT_QUERY = gql`
    query MultiLogInit(
        $clientToken: String,
        $userId: String,
    ) {
        multiLogInit (userId: $userId, clientToken: $clientToken) {
          clientToken,
          apiToken,
          userId,
        }
    }
`;

const MULTILOG_LOG_MUTATION = gql`
    mutation MultiLog(
        $logLevel: Int!,
        $apiToken: String,
        $clientToken: String,
        $gqlToken: String,
        $userId: String,
        $machineId: String,
        $logPath: String,
        $logText: String
    ) {
        multiLog (
          logLevel: $logLevel,
          apiToken: $apiToken,
          clientToken: $clientToken,
          gqlToken: $gqlToken,
          userId: $userId,
          machineId: $machineId,
          logPath: $logPath,
          logText: $logText
        ) {
          logLevel,
          apiToken,
          clientToken,
          gqlToken,
          userId,
          machineId,
          logPath,
          logText
        }
    }
`;

const GET_ALL_USERS = gql`
    query GetAllUsers {
        getAllUsers {
          id
          userEmail
        }
    }
`;

type MultiLog = {
    clientToken: string;
    apiToken: string;
    gqlToken: string;
    logDate: string;
    logLevel: number;
    logPath: string;
    logText: string;
    machineId: string;
    userId: string;
}
interface MultilogContextProps {
    multilogCreate: (userId: string, clientToken: string) => void;
    multilogInit: (
      userId: string,
      clientToken: string,
      machineId: string,
      path: string,
      logLevel: number,
      text: string
    ) => void;
    multilog: MultiLog | undefined;
    multilogLog: (
      clientToken: string,
      logLevel: number,
      logPath: string,
      logText: string,
      machineId: string,
      userId: string
    ) => Promise<MultiLog | undefined>;
  }



const MultilogContext = createContext<MultilogContextProps | undefined>(undefined);
// const clientToken = generateGUID();
const emptyGuid = '11111111-1111-4111-8111-111111111111';

interface MultilogProviderProps {
  children: React.ReactNode;
  clientToken: string;
}

export const MultilogProvider: React.FC<MultilogProviderProps> = ({ children,clientToken }) => {
  const [multilog, setMultilog] = useState<MultiLog | undefined>(undefined);
  const [multilogLogMutation] = useMutation(MULTILOG_LOG_MUTATION);
  const { data, error, refetch: executeMultilogInitQuery } = useQuery(MULTILOG_INIT_QUERY, {
    skip: true, // Skip the initial query execution
  });
  const { user } = useAuth()
  const { data: usersData } = useQuery(GET_ALL_USERS);
  const userId = usersData?.getAllUsers.find((u: any) => u.userEmail === user?.email)?.id || emptyGuid;

  React.useEffect(() => {
    if (!multilog) {
      multilogInit(clientToken, userId || '');
    }
  },[]);

  const multilogCreate = async (
    userId: string,
    clientToken: string,
  ) => {
    setMultilog({
      userId: userId,
      clientToken: clientToken,
      apiToken: '',
      gqlToken: '',
      logDate: '',
      logLevel: 0,
      logPath: '',
      logText: '',
      machineId: '',
    });
    return multilog;
  }

  const multilogInit = async (
    clientToken: string,
    userId: string,
  ) => {
    const result = await executeMultilogInitQuery({clientToken, userId})
    if (error) {
      console.log(`Log error: ${error.message}`);
    } else {
      setMultilog(result.data.multiLogInit);
    }
  };

  const multilogLog = async (
    clientToken: string,
    logLevel: number,
    logPath: string,
    logText: string,
    machineId: string,
    userId: string
  ) => {
    try {
      const { data } = await multilogLogMutation({
        variables: {
            clientToken: clientToken ? clientToken : multilog?.clientToken,
            logLevel: logLevel ? logLevel : multilog?.logLevel,
            logPath: logPath ? logPath : multilog?.logPath,
            logText: logText ? logText : multilog?.logText,
            machineId: machineId ? machineId : multilog?.machineId,
            userId: userId ? userId : multilog?.userId,
        },
      });
      return data?.multiLog ?? undefined;
    } catch (error: any) {
      console.log(`Log error: ${error.message}`);
    }
  };

  return (
    <MultilogContext.Provider value={{ multilog, multilogCreate, multilogInit, multilogLog }}>
      {children}
    </MultilogContext.Provider>
  );
};

export const useMultilog = () => {
  const value = useContext(MultilogContext)

  if (!value) {
      throw new Error('MultilogContext must be used within a MultilogProvider')
  }

  return value
}